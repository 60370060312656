import { faFacebook, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'

const resumeData = {
  "main": {
    "name": "Abu Sayem Sarkar",
    "occupation": "a Frontend Web Developer",
    "description": "A self-motivated and enthusiastic web developer with a deep interest in JavaScript. I have professional experience in JavaScript and React. Always try to grab the new features of technology. To keep growing using adaptive and learning skills and to enhance myself as a professional committed to his work. while concurrently maximizing efficiency.",
    "image": "https://i.ibb.co/JyRzjDN/Abu-Sayem-Sarkar.jpg",
    "bio": "",
    "contactmessage": "Please let me know if you have any questions.",
    "email": "sayemsarkar123@gmail.com",
    "phone": "+880 186 417 0824",
    "address": {
      "street": "123, Mohammadpur",
      "city": "Chattogram",
      "state": "",
      "zip": ""
    },
    "website": "",
    "resumedownload": "https://drive.google.com/uc?id=1ACfG4ODW8NTDYmqq7-dhSpnPk922qslP&export=download",
    "social": [
      {
        "name": "Facebook",
        "url": "https://www.facebook.com/sayemsarkar123",
        "icon": faFacebook
      },
      {
        "name": "LinkedIn",
        "url": "https://www.linkedin.com/in/sayemsarkar123/",
        "icon": faLinkedin
      },
      {
        "name": "GitHub",
        "url": "http://github.com/sayemsarkar123",
        "icon": faGithub
      }
    ]
  },
  "blog": [
    {
      "title": "10 Must-Know JavaScript Array Methods",
      "url": "https://sayemsarkar123.medium.com/10-must-know-javascript-array-methods-d60a0ac99dc6",
      "img": "https://miro.medium.com/max/1000/0*i3x9u7PH1rgX43wB.png",
      "tags": ["JavaScript", "Javascript Tips"],
      "author": "Abu Sayem Sarkar"
    },
    {
      "title": "Important discussion about JavaScript",
      "url": "https://sayemsarkar123.medium.com/important-discussion-about-javascript-993fcfa5f911",
      "img": "https://miro.medium.com/max/700/0*YmItmcw_TFRd3izz",
      "tags": ["JavaScript", "Javascript Tips"],
      "author": "Abu Sayem Sarkar"
    },
    {
      "title": "React.js Simple Overview",
      "url": "https://sayemsarkar123.medium.com/react-js-simple-overview-f881fc7907c5",
      "img": "https://miro.medium.com/max/700/0*9WgIWHpgOEnUMJ8P.jpg",
      "tags": ["JavaScript", "Javascript Frameworks", "Reactjs", "React"],
      "author": "Abu Sayem Sarkar"
    },
    {
      "title": "Javascript Important Topic Revision",
      "url": "https://sayemsarkar123.medium.com/javascript-important-topic-revision-9e46f85ac5ba",
      "img": "https://miro.medium.com/max/700/0*WWIj259SPgWADYiH.jpg",
      "tags": ["JavaScript", "Javascript Tips"],
      "author": "Abu Sayem Sarkar"
    }
  ],
  "resume": {
    "skillmessage": "",
    "education": [
      {
        "school": "",
        "degree": "",
        "graduated": "",
        "description": ""
      },
      {
        "school": "",
        "degree": "",
        "graduated": "",
        "description": ""
      }
    ],
    "work": [
      {
        "company": "",
        "title": "",
        "years": "",
        "description": ""
      },
      {
        "company": "",
        "title": "",
        "years": "",
        "description": ""
      }
    ],
    "skills": [
      {
        "name": "JavaScript",
        "level": "80%"
      },
      {
        "name": "React.js",
        "level": "80%"
      },
      {
        "name": "Node.js",
        "level": "75%"
      },
      {
        "name": "Express.js",
        "level": "75%"
      },
      {
        "name": "MongoDB",
        "level": "75%"
      },
      {
        "name": "Git",
        "level": "80%"
      },
      {
        "name": "HTML5",
        "level": "95%"
      },
      {
        "name": "CSS3",
        "level": "90%"
      }
    ]
  },
  "portfolio": {
    "projects": [
      {
        "title": "Creative Agency",
        "overview": "A web application where users can select the service of their choice. users can place their orders and give feedback. The admin able to view the order of all users and change the status of the order.",
        "technology": ["React.js", "Node.js", "Express.js", "MongoDB", "Firebase", "Bootstrap"],
        "imgurl": "https://i.ibb.co/g6RNymk/1-Landing-page.png",
        "repo": "https://github.com/sayemsarkar123/creative-agency-client",
        "live": "https://creative-agency-2020.web.app/"
      },
      {
        "title": "Volunteer Network",
        "overview": "Volunteer Network is a web application. Where a user can register as a volunteer. The admin of the volunteer network can see the activities of all the volunteers and can delete them if he wants.",
        "technology": ["React.js", "Node.js", "Express.js", "MongoDB", "Firebase", "Bootstrap"],
        "imgurl": "https://i.ibb.co/Z6PBL0L/2-Landing-page.png",
        "repo": "https://github.com/sayemsarkar123/volunteer-network",
        "live": "https://volunteer-network-2020-9ae4b.web.app/"
      },
      {
        "title": "Travel Guru",
        "overview": "Travel Guru is a simple react web application. Where a user can select his destination with a date. The user will be able to see the available hotels of his choice.",
        "technology": ["React.js", "Node.js", "Express.js", "MongoDB", "Firebase", "Bootstrap"],
        "imgurl": "https://i.ibb.co/ZxzyMXM/Home.png",
        "repo": "https://github.com/sayemsarkar123/travel-guru",
        "live": "https://travel-guru-2020.web.app/"
      },
      {
        "title": "Apartment Hunt",
        "overview": "Apartment Hunt offers different types of house rent to their users. Users can take the house rent of their choice and manage it as a user. The admin of Apartment Hunt can review and manage all orders as an admin.",
        "technology": ["React.js", "Node.js", "Express.js", "MongoDB", "Firebase", "Bootstrap"],
        "imgurl": "https://i.ibb.co/fYrL0q6/Apartment-Hunt.png",
        "repo": "https://github.com/sayemsarkar123/apartment-hunt-client",
        "live": "https://apartment-hunt-20cec.web.app/"
      },
      {
        "title": "Athena Design",
        "overview": "Athena Design is a design company that provides a variety of design related services. The Athena company provides interfaces, prototyping and illustrations for their clients. Where users can choose different types of packages of their choice.",
        "technology": ["HTML5", "CSS3", "Bootstrap"],
        "imgurl": "https://i.ibb.co/b3BBp66/Athena-Design.png",
        "repo": "https://github.com/sayemsarkar123/athena-design",
        "live": "https://sayemsarkar123.github.io/athena-design/"
      }
    ]
  },
  "testimonials": {
    "testimonials": [
      {
        "text": "",
        "user": ""
      },
      {
        "text": "",
        "user": ""
      }
    ]
  }
}

export default resumeData